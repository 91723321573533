<!-- <section class="section banner" [style.background-image]="'url(' + ('Banner.Image' | translate) + ')'"> -->
<section class="section banner img" [style.background-image]="'url(' + ('Banner.Image' | translate) + ')'">
    <div [@bannerTrigger] class="container" >
        <div class="section-box-banner">
            <div class="content">
                <div class='banner-title'>
                    <h2>{{"Banner.Title" | translate}}</h2>
                    <h3>{{"Banner.Pretitle" | translate}}</h3>
                </div>
            </div>   
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")' href="mailto:sourabh.joshi@gmail.com" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
           
        </div>
    </div>
</section>



