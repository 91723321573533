import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { visitAll } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  constructor(
    public translateService: TranslateService,
    private location: Location
  ) {}

  loadTree(): any {
    let language = this.translateService.getDefaultLang();
    //language = 'mytree-' + language;
    return this.translateService.getTranslation(language)
      .subscribe((val) =>{ 
        console.log(val.FamilyTree);
        val.FamilyTree});

  }
}
