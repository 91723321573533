<footer>
    <div [@animateFooter] >
        <ul class="footer-left-bar d-none d-md-block">
            <li *ngIf='"AboutUs?.Github" | translate'>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' [href]='"AboutUs.Github" | translate' target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li *ngIf='"AboutUs?.LinkedIn" | translate'>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "linkedin")' [href]='"AboutUs.LinkedIn" | translate' target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' [href]='"AboutUs.Email" | translate' target='_blank'> </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio", "footer", "click")' href="" target="_blank" rel="nofollow noopener noreferrer">
            <div>Created by SRB</div>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio", "footer", "click")' href="" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed by Arrows Lab</div>
        </a>
    </div>

</footer>