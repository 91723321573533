import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { TreeService } from './services/treeservice/tree.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'arrows-lab';
  
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private languageService: LanguageService,
    private treeService : TreeService
    ){
    }
  ngOnInit(): void{
    this.languageService.initLanguage()
    this.treeService.loadTree();

    this.titleService.setTitle( "" );

    this.metaService.addTags([
      {name: 'keywords', content: ''},
      {name: 'description', content: ''},
    ]);
    
    AOS.init(); 

  }
}
