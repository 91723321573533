<main>
    <app-banner></app-banner>
    <app-about></app-about>
    <app-our-services></app-our-services>
    <!-- <app-jobs></app-jobs> -->
    <app-proyects></app-proyects>
    <!-- <app-my-tree></app-my-tree> -->
    <app-more-proyects></app-more-proyects>
    <app-contact></app-contact>
    
</main>

