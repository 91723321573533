<nav
  [@animateMenu]
  class="navbar main-navbar on-top"
  [class.nav-shadow]="this.pageYPosition > 0"
>
  <div class="container">
    <a class="navbar-brand text-light" [routerLink]="'about-me'">
      <img src="assets/images/logo.png" width="25" />
    </a>
    <ul ngbNav #nav="ngbNav" class="menu-ul">
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_about', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('about')"
          ><span class="nav-number">01. </span>
          <span class="underline nav-text">
            {{ "Header.Item1" | translate }}</span
          ></a
        >
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent(
            'click_experience',
            'menu',
            'click'
          )
        "
      >
        <a ngbNavLink (click)="scroll('jobs')"
          ><span class="nav-number">02. </span>
          <span class="underline nav-text">
            {{ "Header.Item2" | translate }}</span
          ></a
        >
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_jobs', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('proyects')"
          ><span class="nav-number">03. </span>
          <span class="underline nav-text">
            {{ "Header.Item3" | translate }}</span
          ></a
        >
      </li>
      <li
        ngbNavItem
        (click)="
          analyticsService.sendAnalyticEvent('click_contact', 'menu', 'click')
        "
      >
        <a ngbNavLink (click)="scroll('contact')"
          ><span class="nav-number">04. </span>
          <span class="underline nav-text">
            {{ "Header.Item4" | translate }}</span
          ></a>
      </li>
      <li
        ngbNavItem
        (click)="analyticsService.sendAnalyticEvent('click_contact', 'menu', 'click')">
        <a ngbNavLink (click)="scroll('contact')"
          ><span class="nav-number">05. </span>
          <span class="underline nav-text">
            {{ "Header.Item5" | translate }}</span
          ></a>
      </li>

      <!-- <li ngbNavItem>
                <a (click)="onThemeChanged()" class="menu-ul underline nav-text cv-btn" ngbNavLink>
                  changeTheme
                </a>
            </li> -->
      <!-- <li ngbNavItem *ngIf="(auth.isAuthenticated$ | async) === false">
        <a
          (click)="loginWithRedirect()"
          class="menu-ul underline nav-text cv-btn"
          ngbNavLink
        >
          Log in
        </a>
      </li> -->
      
    </ul>
    <div class="menu-wrapper">
      <div
        [class.animate]="responsiveMenuVisible"
        (click)="responsiveMenuVisible = !responsiveMenuVisible"
        class="hamburger-menu"
      ></div>
    </div>
  </div>
</nav>
<!-- <div class="menu-responsive" [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
            <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
                <nav>
                    <ol>
                        <li (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                            <a (click)='scroll("about")'>
                                <span>01. </span> {{"Header.Item1" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                            <a (click)='scroll("jobs")'>
                                <span>02. </span> {{"Header.Item2" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                          <a (click)='scroll("proyects")'>
                              <span>03. </span> {{"Header.Item3" | translate}}
                          </a>
                      </li>
                      <li (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                        <a (click)='scroll("proyects")'>
                            <span>04. </span> {{"Header.Item4" | translate}}
                        </a>
                      </li>
                      <li>
                          <a (click)="downloadCV()" class="main-btn cv-btn">
                            {{"Header.cvBtn" | translate}}
                          </a>
                      </li>
                      <li>
                            <div class="language-container">
                                <div (click)='changeLanguage("es")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "es"'>
                                    <img src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                                    <span class="flag-text">Español</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                      </svg>
                                </div>
                                <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                                    <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                                    <span class="flag-text">English</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                    </svg>
                                </div>
                            </div>
                      </li>-->
<!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->

<!--  </ol>
                </nav>
            </aside>
        </div> -->
