<section class="section my-tree-section" id="showtree"  >
  <div class="container">
    <div class="section-box">
      <div class="">
        <h3 class="section-title">
          <span class="n-section-title">05.</span>
          {{ "OtherProjects.Title" | translate }}
        </h3>
      </div>
      <div class="mt-5">
        <div class="row p-0">
          <p-organizationChart
            [value]="data1"
            [collapsible]="true" 
          >
            <ng-template let-node pTemplate="default" (click)="redirect(project['demoLink'], $event)" class="col-12 col-md-6 col-lg-6 col-xl-4"
            data-aos="fade-up"
            data-aos-duration="1000">
                <div class="my-tree-box">
                  <div class="row w-100 text-left m-0 p-0">
                    <div class="col-6 p-0" >
                      <img src="assets/icons/folder.svg" width="40" />
                    </div>
                    <h5 class="tree-node-title mt-4">{{ node.label }}</h5>
                    <p class="tree-node-description">
                      {{ node.data?.name }}
                    </p>
                    
                    <p *ngIf="!node.children || node.children.length === 0">
                      <a (click)='analyticsService.sendAnalyticEvent("click_pokemonlist_external_link", "proyects", "click")' style='color: inherit' [href]="showDialog(node)" target="_blank"><i id="demoLink" class="ml-3 fas fa-external-link-alt"></i></a>
                    </p>
                  </div>
                </div>
            </ng-template>
          </p-organizationChart>
        </div>
      </div>
      <!-- <div class="w-100 text-center mt-5 pt-5">
              <a [routerLink]="'/proyectos'" class='main-btn'>Ver todo</a>
          </div> -->
    </div>
  </div>
  <!-- Dialog for adding a new node -->
  <!-- <p-dialog
  header="Add Node"
  [(visible)]="displayDialog"
  [modal]="true"
  [closable]="true"
  (onHide)="onDialogHide()"
>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <label for="name">Name</label>
      <input id="name" type="text" pInputText placeholder="Enter name" />
    </div>
    <div class="p-col-12">
      <label for="avatar">Avatar URL</label>
      <input
        id="avatar"
        type="text"
        pInputText
        placeholder="Enter avatar URL"
      />
    </div>
    <div class="p-col-12">
      <button pButton type="button" label="Add" (click)="addNode()">
        Add Node
      </button>
    </div>
  </div>
</p-dialog> -->
</section>
