import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { ProfileComponent } from './components/home/profile/profile.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { MyTreeComponent } from './components/home/my-tree/my-tree.component';

const routes: Routes = [
  { path: ':language?', component: HomeComponent },
  { path: ':language?/proyectos', component: ArchiveComponent },

  {path: 'profile'        ,   component: ProfileComponent         , canActivate: [AuthGuard]},
  {path: 'my-tree'        ,   component: MyTreeComponent         , canActivate: [AuthGuard]},
  // {path: 'users'          ,   component: UsersComponent           , canActivate: [AuthGuard]},
  // {path: 'register-user'  ,   component: RegisterUserComponent    , canActivate: [AuthGuard]},

  { path: '**', pathMatch: 'full', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
