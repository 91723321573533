import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { TreeService } from 'src/app/services/treeservice/tree.service';
@Component({
  selector: 'app-my-tree',
  templateUrl: './my-tree.component.html',
  styleUrl: './my-tree.component.scss',
  providers: [MessageService],
})
export class MyTreeComponent implements OnChanges {
  data1: TreeNode[];

  data2: TreeNode[];

  selectedNode: TreeNode;

  nodeHovered: any = null;

  displayDialog: boolean = false;

  newNode = { name: '', avatar: '' };
  @Input() someInputProperty: any;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    let language = this.translateService.getDefaultLang();
    this.translateService.getTranslation(language).subscribe((val) => {

      this.data1 = val.FamilyTree;
    });
  }

  showDialog(node: any) {
    this.displayDialog = true;
    this.selectedNode = node;
    this.newNode = { name: '', avatar: '' }; // Reset form fields
  }

  ngOnChanges(changes: any) {
    if (changes.someInputProperty) {
      // Update the expression here...
    }
  }
  addNode() {
    if (!this.selectedNode.children) {
      this.selectedNode.children = [];
    }

    const nodeToAdd = {
      label: this.newNode.name || 'New Node',
      type: 'person',
      styleClass: 'p-person',
      data: { name: this.newNode.name, avatar: this.newNode.avatar },
    };

    this.selectedNode.children.push(nodeToAdd);
    this.displayDialog = false; // Close dialog
  }

  onDialogHide() {
    this.newNode = { name: '', avatar: '' }; // Reset form fields
  }
  onNodeSelect(event) {
    this.messageService.add({
      severity: 'success',
      summary: 'Node Selected',
      detail: event.node.label,
    });
  }
}
